// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CreateBoard from './CreateBoard';
import BingoBoard from './BingoBoard';
import ModeratorBoard from './ModeratorBoard';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<CreateBoard />} />
          <Route path="/board/:boardUid/" element={<BingoBoard />} />
          <Route path="/moderator/:boardUid" element={<ModeratorBoard />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
