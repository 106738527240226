// src/ModeratorBoard.js
import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { useParams } from 'react-router-dom';
import { useSprings, animated } from 'react-spring';
import './BingoBoard.css';
import './ModeratorBoard.css';
import { hyphenated } from 'hyphenated';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import options from './options';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));


const socket = io(options.url, options.socket);

console.log("hi")

const ModeratorBoard = () => {
    console.log("hi")
  const { boardUid } = useParams();
  const [boardId, setBoardId] = useState([]);
  const [board, setBoard] = useState([]);
  const [boardSize, setBoardSize] = useState(5);
  const [colors, setColors] = useState({ player1Color: '#ff4d4d', player2Color: '#2196f3' });
  const [currentPlayer, setCurrentPlayer] = useState('player1');
  const [powerups, setPowerups] = useState({});
  const [bingoLines, setBingoLines] = useState([]); // Array to hold the indices of the bingo lines
  const [points, setPoints] = useState({ player1: 0, player2: 0 });
  const [positions, setPositions] = useState([]);
  const [playerNames, setPlayerNames] = useState({ player1: 'Player 1', player2: 'Player 2' });
  const [springs, setSprings] = useSprings(boardSize * boardSize, (index) => ({
    top: positions[index]?.top || '0px',
    left: positions[index]?.left || '0px',
    from: { top: positions[index]?.top || '0px', left: positions[index]?.left || '0px' },
    config: { tension: 200, friction: 20 },
  }));
  const [freezeMode, setFreezeMode] = useState(false);
  const [timer, setTimer] = useState(0);

  
  useEffect(() => {
    socket.emit('joinBoard', { boardUid, player: 'moderator' });

    socket.on('initialState', ({ state, colors, points, size, playerNames, timerDuration, timeLeft, boardId }) => {
      setBoardId(boardId)
      setBoard(state);
      setColors(colors);
      setBoardSize(size || 5);
      checkForBingo(state, size || 5);
      setPoints(points);
      setPlayerNames(playerNames);
      const newPositions = generatePositions(state.length, size || 5);
      setPositions(newPositions);
      setSprings((index) => ({
        top: newPositions[index]?.top || '0px',
        left: newPositions[index]?.left || '0px',
        from: { top: newPositions[index]?.top || '0px', left: newPositions[index]?.left || '0px' },
        config: { tension: 200, friction: 20 },
      }));
      setTimer(timeLeft);

      console.log(timeLeft, timerDuration)
    });

    socket.on('timerUpdate', ({ timeLeft }) => {
      setTimer(timeLeft);
    });

    socket.on('timerEnd', () => {
      // handle timer end
      setTimer(0)
    });

    socket.on('powerups', (allPowerups) => {
      console.log(allPowerups)
      if (allPowerups) {
        setPowerups(allPowerups);
      }
    });

    socket.on('randomState', ({ randomState, points }) => {
      animateShuffle(randomState, boardSize);
      checkForBingo(randomState, boardSize);
      setPoints(points);
    });

    socket.on('updateBoard', ({ index, player, points, frozen }) => {
      setBoard((prevBoard) => {
        const newBoard = [...prevBoard];
        newBoard[index].owner = player;
        if (frozen !== undefined) {
          newBoard[index].frozen = frozen;
        }
        checkForBingo(newBoard, boardSize);
        return newBoard;
      });
      setPoints(points);
    });

    return () => {
      socket.off('initialState');
      socket.off('updateBoard');
      socket.off('randomState');
      socket.off('powerups');
    };
  }, [boardId, boardSize]);

  const generatePositions = (length, size) => {
    console.log(length, size)
    const positions = [];
    for (let i = 0; i < length; i++) {
      const row = Math.floor(i / size);
      const col = i % size;
      positions.push({ top: `${(row * 100) / size}%`, left: `${(col * 100) / size}%` });
    }
    return positions;
  };


  const animateShuffle = (newBoard, size) => {
    console.log(size)

    let half = (50 - 100/(boardSize*2)) + "%"

    setSprings((index) => ({
      top: half,
      left: half,
      from: { top: positions[index]?.top, left: positions[index]?.left},
      config: { tension: 200, friction: 20 },
    }));

    setTimeout(() => {
      const newPositions = generatePositions(newBoard.length, size);
      setPositions(newPositions);
      setBoard(newBoard);
      setSprings((index) => ({
        top: newPositions[index]?.top ,
        left: newPositions[index]?.left ,
        from: { top: half, left: half },
        config: { tension: 200, friction: 20 },
      }));
    }, 500);
  };

  const checkForBingo = (board, size) => {
    const lines = [];

    // Check rows
    for (let row = 0; row < size; row++) {
      const start = row * size;
      const end = start + size;
      const rowCells = board.slice(start, end);
      if (rowCells.every((cell) => cell.owner === 'player1') || rowCells.every((cell) => cell.owner === 'player2')) {
        lines.push({ type: 'row', index: row });
      }
    }

    // Check columns
    for (let col = 0; col < size; col++) {
      const colCells = [];
      for (let row = 0; row < size; row++) {
        colCells.push(board[row * size + col]);
      }
      if (colCells.every((cell) => cell.owner === 'player1') || colCells.every((cell) => cell.owner === 'player2')) {
        lines.push({ type: 'col', index: col });
      }
    }

    // Check diagonals
    const diag1 = [];
    const diag2 = [];
    for (let i = 0; i < size; i++) {
      diag1.push(board[i * size + i]);
      diag2.push(board[(i + 1) * size - (i + 1)]);
    }
    if (diag1.every((cell) => cell.owner === 'player1') || diag1.every((cell) => cell.owner === 'player2')) {
      lines.push({ type: 'diag', index: 1 });
    }
    if (diag2.every((cell) => cell.owner === 'player1') || diag2.every((cell) => cell.owner === 'player2')) {
      lines.push({ type: 'diag', index: 2 });
    }

    setBingoLines(lines);
  };


  const handleCellClick = (index) => {
    if (freezeMode) {
      handleFreeze(index);
      setFreezeMode(false);
    } else {
      socket.emit('moderatorMarkCell', { boardId, index, player: currentPlayer });
    }
    
  };

  const handleShuffle = () => {
    if (powerups[currentPlayer].shuffles > 0) {
      socket.emit('shuffleBoard', { boardId, player: currentPlayer });
    }
  };

  const handleFreeze = (index) => {
    if (powerups[currentPlayer].freezes > 0) {
      socket.emit('freezeCell', { boardId, index, player: currentPlayer });
    }
  };

  const getCellClassName = (index) => {
    if (!board[index]) return 'bingo-cell';
    const size = boardSize;
    const baseClass = `bingo-cell ${board[index].owner === currentPlayer ? 'marked-' + currentPlayer : board[index].owner ? 'marked-' + board[index].owner : ''}`;
    const isBingoCell = bingoLines.some((line) => {
      if (line.type === 'row') {
        return Math.floor(index / size) === line.index;
      } else if (line.type === 'col') {
        return index % size === line.index;
      } else if (line.type === 'diag') {
        if (line.index === 1) {
          return index % (size + 1) === 0;
        } else if (line.index === 2) {
          return index % (size - 1) === 0 && index !== 0 && index !== size * size - 1; // Corrected condition
        }
      }
      return false;
    });
    return `${baseClass} ${isBingoCell ? 'bingo-line' : ''} ${board[index].frozen ? 'frozen' : ''}`;
  };

  const handleStartTimer = () => {
    socket.emit('startTimer', { boardId });
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    
    const mins = Math.floor(seconds / 60 - hours*60);
    
    const secs = seconds % 60;
    
    return `${hours}:${mins < 10 ? '0' : ''}${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };
  return (
    <div className="bingo-container">
      <h1>Moderator Bingo Board</h1>
      <div className="player-selector">
        <button onClick={() => setCurrentPlayer('player1')} style={{ backgroundColor: colors.player1Color }}>
          Player 1
        </button>
        <button onClick={() => setCurrentPlayer('player2')} style={{ backgroundColor: colors.player2Color }}>
          Player 2
        </button>
      </div>

      <div className="points">
        <p
          style={{backgroundColor: colors.player1Color}}
        >{playerNames.player1}: {points.player1}</p>
        <p
          style={{backgroundColor: colors.player2Color}}
        >{playerNames.player2}: {points.player2}</p>
      </div>

      <div className="timer">
        {<p>Time Left: {formatTime(timer)}</p>}
      </div>

      <div className="bingo-board" style={{ borderColor: colors[currentPlayer + 'Color'], gridTemplateColumns: `repeat(${boardSize}, 1fr)` }}>
        {springs.map((props, index) => (
          <animated.div
            key={index}
            className={getCellClassName(index)}
            style={{
              ...props,
              width: `${100 / boardSize}%`,
              height: `${100 / boardSize}%`,
              backgroundColor: board[index]?.owner === 'player1' ? colors.player1Color : board[index]?.owner === 'player2' ? colors.player2Color : '',
              opacity: board[index]?.frozen ? 0.5 : 1,
              fontSize: `${80/boardSize}px`
            }}
            onClick={() => handleCellClick(index)}
          >
            <div className="bingo-text">
              {hyphenated(board[index]?.text)}
            </div>
          </animated.div>
        ))}
      </div>
        <div className="powerup-container">
          {powerups !== undefined && powerups[currentPlayer] !== undefined &&powerups[currentPlayer].shuffles !== undefined && (
            <BootstrapTooltip title="Shuffle the Board">
              <button className="shuffle-button" onClick={handleShuffle} disabled={powerups[currentPlayer].shuffles <= 0}>
                <ShuffleIcon />
                <span className="shuffle-count">{powerups[currentPlayer].shuffles}</span>
              </button>
            </BootstrapTooltip>
          )}
          {powerups !== undefined && powerups[currentPlayer] !== undefined && powerups[currentPlayer].freezes !== undefined && (
            <BootstrapTooltip title="Freeze one cell">
              <button 
              className={`freeze-button ${freezeMode ? 'active' : ''}`} 
                disabled={powerups[currentPlayer].freezes <= 0}
                onClick={() => setFreezeMode(!freezeMode)} 
                >
                <AcUnitIcon />
                <span className="freeze-count">{powerups[currentPlayer].freezes}</span>
              </button>
            </BootstrapTooltip>
          )}
          <button className="start-timer-button" onClick={handleStartTimer}>Start Timer</button>
        </div>
    </div>
  );
};

export default ModeratorBoard;
