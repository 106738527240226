let options = {
    url: 'https://backend.shrlc.de',
    socket: {
        secure: true,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity
      }
}

module.exports = options