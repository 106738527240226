// src/CreateBoard.js
import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import './CreateBoard.css';
import Slider from '@mui/material/Slider';

import options from './options';

const socket = io(options.url, options.socket);

const getRandomResult = (probabilities) => {
  // Berechnung der Summe der Wahrscheinlichkeiten
  const total = probabilities.reduce((sum, prob) => sum + prob, 0);

  // Generiere eine zufällige Zahl zwischen 0 und der Summe der Wahrscheinlichkeiten
  let random = Math.random() * total;

  // Finde das entsprechende Ergebnis
  for (let i = 0; i < probabilities.length; i++) {
      if (random < probabilities[i]) {
          return i;
      }
      random -= probabilities[i];
  }

  // Falls kein Ergebnis gefunden wurde (was theoretisch nicht passieren sollte)
  return -1;
}

const CreateBoard = () => {
  const [items, setItems] = useState('');
  const [links, setLinks] = useState(null);
  const [usePowerups, setUsePowerups] = useState(false);
  const [shufflesEnabled, setShufflesEnabled] = useState(false);
  const [shuffles, setShuffles] = useState(0);
  const [freezesEnabled, setFreezesEnabled] = useState(false);
  const [freezes, setFreezes] = useState(0);
  const [powerupMode, setPowerupMode] = useState('Fix');
  const [totalPowerups, setTotalPowerups] = useState(0);
  const [shuffleProbability, setShuffleProbability] = useState(50);
  const [freezeProbability, setFreezeProbability] = useState(50);
  const [player1Color, setPlayer1Color] = useState('#ff4d4d');
  const [player2Color, setPlayer2Color] = useState('#2196f3');
  const [bonusPoints, setBonusPoints] = useState(0);
  const [player1Name, setPlayer1Name] = useState('Player 1');
  const [player2Name, setPlayer2Name] = useState('Player 2');
  const [boardSize, setBoardSize] = useState(5);
  const [timerDuration, setTimerDuration] = useState(3600);

  useEffect(() => {
    if (shuffleProbability + freezeProbability !== 100) {
      const total = shuffleProbability + freezeProbability;
      if (total > 100) {
        const excess = total - 100;
        if (shuffleProbability >= freezeProbability) {
          setShuffleProbability(prev => prev - excess);
        } else {
          setFreezeProbability(prev => prev - excess);
        }
      } else {
        const deficit = 100 - total;
        if (shuffleProbability >= freezeProbability) {
          setShuffleProbability(prev => prev + deficit);
        } else {
          setFreezeProbability(prev => prev + deficit);
        }
      }
    }
  }, [shuffleProbability, freezeProbability]);

  const handleCreateBoard = () => {
    const itemList = items.split('\n').filter(item => item.trim() !== '');
    if (itemList.length < boardSize * boardSize) {
      alert(`Please enter at least ${boardSize * boardSize} items.`);
      return;
    }

    // Shuffle the list and pick the first 25 items
    const shuffledList = itemList.sort(() => 0.5 - Math.random()).slice(0, boardSize * boardSize);

    const powerups = usePowerups ? calculatePowerups() : {};

    const colors = {
      player1Color,
      player2Color
    };

    const playerNames = {
      player1: player1Name,
      player2: player2Name
    };

    socket.emit('createBoard', { boardItems: shuffledList, powerups, colors, bonusPoints, playerNames, boardSize, timerDuration });
  };

  const calculatePowerups = () => {
    let calculatedPowerups = {};
    let probabilities = [shufflesEnabled ? shuffleProbability : 0, freezesEnabled ? freezeProbability : 0]
    

    if (powerupMode === 'Fix') {

      if (shufflesEnabled) {
        calculatedPowerups.shuffles = shuffles;
      }

      if (freezesEnabled) {
        calculatedPowerups.freezes = freezes;
      }

    } else if (powerupMode === 'Random') {

      const total = totalPowerups;
      let possibleWinners = ["shuffles", "freezes"]

      for ( let i=0; i<total; i++){
        let winner = getRandomResult(probabilities)
        if(possibleWinners[winner] === undefined) return
        if(calculatedPowerups[possibleWinners[winner]] == undefined){
          calculatedPowerups[possibleWinners[winner]] = 1
        }else{
          calculatedPowerups[possibleWinners[winner]]++
        }
      }

    } else if (powerupMode === 'RandomEx') {

      let possibleWinners = [{shuffles}, {freezes}]

      let winner = getRandomResult(probabilities)

      console.log(winner)

      calculatedPowerups = possibleWinners[winner]

    }
    console.log(calculatedPowerups)
    return calculatedPowerups;
  };

  const handleSliderChange = (setter, otherSetter) => (event, newValue) => {
    const otherValue = 100 - newValue;
    setter(newValue);
    otherSetter(otherValue);
  };

  socket.on('boardCreated', (data) => {
    setLinks(data);
  });

  return (
    <div className="create-board-container">
      <h1>Create Bingo Board</h1>
      <textarea
        rows="10"
        value={items}
        onChange={(e) => setItems(e.target.value)}
        placeholder="Enter items, one per line"
      ></textarea>
      <div>
        <label>
          <input
            type="checkbox"
            checked={usePowerups}
            onChange={(e) => setUsePowerups(e.target.checked)}
          />
          Use Powerups
        </label>
      </div>
      {usePowerups && (
        <div>
          <div className='player-inputs'>
            <label htmlFor="powerupmode">Powerup Mode:</label>
            <select name="powerupmode" id="powerupmode" value={powerupMode} onChange={(e) => setPowerupMode(e.target.value)}>
              <option value="Random">Random</option>
              <option value="RandomEx">Random Exclusive</option>
              <option value="Fix">Fix</option>
            </select>
          </div>
          {powerupMode === "Random" && (
            <div className='player-inputs'>
              <label>Number of Powerups: </label>
              <input
                type="number"
                value={totalPowerups}
                onChange={(e) => setTotalPowerups(Number(e.target.value))}
                min="0"
              />
            </div>
          )}
          <label>
            <input
              type="checkbox"
              checked={shufflesEnabled}
              onChange={(e) => {setShufflesEnabled(e.target.checked); handleSliderChange(e.target.checked ? setShuffleProbability : setShuffleProbability(0), setFreezeProbability)}}
            />
            Enable Shuffles
          </label>
          {shufflesEnabled && (
            <div>
              {(powerupMode === 'Fix' || powerupMode === 'RandomEx') && (
                <div className='player-inputs'>
                  <label>Number of Shuffles for Player 1 and Player 2:</label>
                  <input
                    type="number"
                    value={shuffles}
                    onChange={(e) => setShuffles(Number(e.target.value))}
                    min="0"
                  />
                </div>
              )}
              {(powerupMode === 'RandomEx' || powerupMode === 'Random') && (
                <div className='player-inputs'>
                  <label>Shuffle Probability:</label>
                  <div className='slider-container'>
                    <Slider
                      value={shuffleProbability}
                      onChange={handleSliderChange(setShuffleProbability, setFreezeProbability)}
                      aria-labelledby="shuffle-slider"
                      valueLabelDisplay="auto"
                      step={1}
                      min={0}
                      max={100}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          <label>
            <input
              type="checkbox"
              checked={freezesEnabled}
              onChange={(e) => {setFreezesEnabled(e.target.checked); handleSliderChange(e.target.checked ? setFreezeProbability : setFreezeProbability(0), setShuffleProbability)}}
            />
            Enable Freezes
          </label>
          {freezesEnabled && (
            <div>
              {(powerupMode === 'Fix' || powerupMode === 'RandomEx') && (
                <div className='player-inputs'>
                  <label>Number of Freezes for Player 1 and Player 2:</label>
                  <input
                    type="number"
                    value={freezes}
                    onChange={(e) => setFreezes(Number(e.target.value))}
                    min="0"
                  />
                </div>
              )}
              {(powerupMode === 'RandomEx' || powerupMode === 'Random') && (
                <div className='player-inputs'>
                  <label>Freeze Probability:</label>
                  <div className='slider-container'>
                    <Slider
                      value={freezeProbability}
                      onChange={handleSliderChange(setFreezeProbability, setShuffleProbability)}
                      aria-labelledby="freeze-slider"
                      valueLabelDisplay="auto"
                      step={1}
                      min={0}
                      max={100}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          <br/><br/>
        </div>
      )}
      <div className='player-inputs'>
        <label>Player 1 Name:</label>
        <input
          type="text"
          value={player1Name}
          onChange={(e) => setPlayer1Name(e.target.value)}
        />
        
        <label>Player 1 Color:</label>
        <input
          type="color"
          value={player1Color}
          onChange={(e) => setPlayer1Color(e.target.value)}
        />
      </div>
      <div className='player-inputs'>
        <label>Player 2 Name:</label>
        <input
          type="text"
          value={player2Name}
          onChange={(e) => setPlayer2Name(e.target.value)}
        />
        <label>Player 2 Color:</label>
        <input
          type="color"
          value={player2Color}
          onChange={(e) => setPlayer2Color(e.target.value)}
        />
      </div>
      <div className='player-inputs'>
        <label>Bonus Points per Bingo:</label>
        <input
          type="number"
          value={bonusPoints}
          onChange={(e) => setBonusPoints(Number(e.target.value))}
          min="0"
        />
      </div>
      <div className='player-inputs'>
        <label>
          Board Size:
          </label>
          <input
            type="number"
            value={boardSize}
            onChange={(e) => setBoardSize(Number(e.target.value))}
            min="3"
            max="10"
          />
      </div>
      <div className='player-inputs'>
        <label>
          Timer Duration (in seconds):
          </label>
          <input
          type="number"
          value={timerDuration}
          onChange={(e) => setTimerDuration(Number(e.target.value))}
          min="0"
        />
      </div>
      <button onClick={handleCreateBoard}>Create Bingo Board</button>
      {links && (
        <div className="create-board-links">
          <p>Player 1 Link: <a href={links.player1Link}>{links.player1Link}</a></p>
          <p>Player 2 Link: <a href={links.player2Link}>{links.player2Link}</a></p>
          <p>View Only Link: <a href={links.viewLink}>{links.viewLink}</a></p>
          <p>Moderator Link: <a href={links.modLink}>{links.modLink}</a></p>
        </div>
      )}
    </div>
  );
};

export default CreateBoard;
